// graphql/queries.ts
import { gql } from "graphql-tag";
import blocksFragment from "~/graphql/fragments/blocks";

/************** Pages ***************/

export const GET_PAGES = gql`
  query Pages {
    pages {
      date_created
      date_updated
      id
      sort
      status
      title
      user_created
      user_updated
      permalink
    }
  }
`;

export const GET_PAGE = gql`
  query Pages($slug: String!) {
    pages(filter: { permalink: { _ncontains: "#", _contains: $slug } }) {
      id
      title
      permalink
      blocks(filter: { hide_block: { _eq: false } }) {
        ...BlocksFragment
      }
      permalink
    }
  }
  ${blocksFragment}
`;

/************** Services *****************/

export const GET_SERVICES = gql`
  query Services {
    services {
      id
      name
      slug
      title
      short_title
      description
      background_image {
        id
        tags
      }
      logo {
        id
        description
        tags
      }
    }
  }
`;

export const GET_SERVICE_BY_SLUG = gql`
  query Services($slug: String!) {
    services(filter: { slug: { _ncontains: "#", _contains: $slug } }) {
      id
      status
      sort
      name
      title
      short_title
      slug
      icon {
        id
        title
        width
        height
        description
        tags
      }
      background_image {
        id
        title
        width
        height
        description
        tags
      }
      description
      service_offers {
        id
        sort
        name
        headline
        service_offer_items {
          id
          sort
          title
          description
        }
        why_us
      }
      case_studies {
        id
        status
        challenge_description
        approach_description
        results_description
        title
        headline
        client_name
        slug
        case_study_date
        card_image {
          id
          title
          width
          height
          description
          tags
        }
      }
    }
  }
`;

/************** Navigation ***************/

export const GET_NAV = gql`
  query Navigation($id: ID!) {
    navigation: navigation_by_id(id: $id) {
      id
      title
      items {
        has_children
        icon
        id
        title
        label
        open_in_new_tab
        url
        page {
          title
          permalink
          id
        }
      }
    }
  }
`;

/************** Posts ***************/

export const GET_POSTS = gql`
  query Posts($slug: String!) {
    posts(limit: 5) {
      id
      slug
      date_created
      date_published
      date_updated
      status
      summary
      title
      content
      category(filter: { slug: { _eq: $slug } }) {
        slug
        id
        title
      }
      read_time
      type
      video_url
      image {
        id
        title
        description
      }
      quote
      author {
        id
        bio
        job_title
        name
        image {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_CASE_STUDIES_BY_SLUG = gql`
  query CaseStudyBySlug($slug: String!) {
    case_studies(filter: { slug: { _eq: $slug } }) {
      id
      status
      title
      slug
      headline
      client_name
      case_study_date
      hero_media {
        id
        title
        description
        tags
      }
      challenge_description
      challenge_media {
        id
        title
        description
      }
      approach_description
      approach_media {
        id
        title
        description
      }
      results_description
      results_media {
        id
        title
        description
      }
    }
  }
`;

export const GET_POST_BY_SLUG = gql`
  query Posts($slug: String!) {
    posts(filter: { slug: { _eq: $slug } }) {
      id
      slug
      date_created
      date_published
      date_updated
      status
      summary
      title
      content
      read_time
      type
      video_url
      image {
        id
        title
        description
      }
      author {
        id
        bio
        job_title
        name
        image {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_INVESTMENTS = gql`
  query Investments {
    investments(limit: 5) {
      id
      slug
      name
      short_description
      challenge
      solution
      results
      logo {
        id
        title
        description
      }
      media {
        id
        title
        description
      }
      media_thumbnail {
        id
        title
        description
      }
      is_video
    }
  }
`;

export const GET_INVESTMENT_BY_SLUG = gql`
  query Investments($slug: String!) {
    investments(filter: { slug: { _eq: $slug } }) {
      id
      slug
      name
      short_description
      challenge
      solution
      results
      date_created
      date_published
      date_updated
      logo {
        id
        title
        description
      }
      media {
        id
        title
        description
      }
      media_thumbnail {
        id
        title
        description
      }
      is_video
    }
  }
`;

export const GET_LATEST_INSIGHTS = gql`
  query Posts {
    posts(limit: 5) {
      content
      date_published
      id
      slug
      summary
      title
      client
      quote
    }
  }
`;

export const GET_LAST_INSIGHT = gql`
  query Posts {
    posts(limit: 1) {
      content
      date_published
      id
      slug
      summary
      title
      client
      quote
      image {
        id
        title
        description
      }
    }
  }
`;

export const GET_TEAM = gql`
  query Team {
    team {
      bio
      id
      job_title
      name
    }
  }
`;

export const GET_FORMS = gql`
  query Forms {
    forms {
      id
      key
      on_success
      redirect_url
      title
    }
  }
`;

/************** Globals ***************/
export const GET_GLOBAL = gql`
  query Globals {
    global: globals {
      id
      description
      og_image {
        id
        title
        description
      }
      email
      phone
      address_country
      address_locality
      address_region
      postal_code
      street_address
      tagline
      title
      url
      social_links
    }
  }
`;

/************** Footer ***************/
export const GET_FOOTER = gql`
  query Footer {
    footer: block_footer {
      id
      title
      headline
      copyright
      background_image {
        id
        title
        description
      }
    }
  }
`;

/************** Our Work - Options ***************/
export const GET_OUR_WORK_OPTIONS = gql`
  query OurWorkOptions {
    options: services {
      label: title
      category: slug
    }
  }
`;

/************** Our Work - Case Studies ***************/
export const GET_OUR_WORK_CASE_STUDIES = gql`
  query OurWorkCaseStudies {
    case_studies {
      id
      slug
      title
      card_image {
        id
        title
        description
      }
      category: service {
        label: title
        name: slug
      }
      case_study_date: case_study_date_func {
        year
      }
    }
  }
`;

export const GET_OUR_WORK_CASE_STUDIES_BY_CATEGORY = gql`
  query OurWorkCaseStudiesByCategory($serviceSlug: String!) {
    case_studies(filter: { service: { slug: { _eq: $serviceSlug } } }) {
      id
      slug
      title
      card_image {
        id
        title
        description
      }
      category: service {
        label: title
        name: slug
      }
      case_study_date: case_study_date_func {
        year
      }
    }
  }
`;

export const GET_OFFICE_LOCATIONS = gql`
  query Countries {
    countries {
        id
        name
        offices {
            id
            city
            address
            contact_number
            office_media {
                id
                title
                description
            }
        }
    }
}`;